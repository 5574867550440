import { AxiosResponse } from "axios";
import React from "react";

import ContentBoxTabTable from "../../../intelws_portal/bundles/ContentBoxTabTable";
import { ICheckboxRepr, IDataRepr, ILinksRepr } from "../../../intelws_portal/constructs/elements/Table";
import { ITabRepr } from "../../../intelws_portal/constructs/elements/Tabs";
import ScrollTop from "../../../intelws_portal/constructs/elements/ScrollTop";
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import { get, getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";

import * as urls from "../../Urls";
import { IPageComponentProps } from "../../../declarations/common";
import { IFetchNotifResponseRepr } from "./AdminMenuMain";
import ContentBoxMultiTabTable, { IContentBoxMultiTabTableTabRepr } from "../../../intelws_portal/bundles/ContentBoxMultiTabTable";

interface IFetchNotificationsConfig extends AxiosResponse {
    data: {
        tab_config: IContentBoxMultiTabTableTabRepr[]
    }
}


function NotificationTable(props: IPageComponentProps) {
    const [tabConfig, setTabConfig] = React.useState<IContentBoxMultiTabTableTabRepr[]>([]);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [currTabTopLevelIndex, setCurrTabTopLevelIndex] = React.useState(0);
    const [currTabSecondLevelIndex, setCurrTabSecondLevelIndex] = React.useState(0);
    const [selectedNotifId, setSelectedNotifId] = React.useState<number[]>([]);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("Notifications");
        }
        let queryParams = {
            section: "notifications_config",
            sub_section: window.location.hash.substring(1)
        }
        const configRequestResponse = get(urls.CONFIG_ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchNotificationsConfig>
        configRequestResponse.then((configRequestResponse) => {
            console.log(configRequestResponse);
            let newTabConfig: IContentBoxMultiTabTableTabRepr[] = [...configRequestResponse.data.tab_config];
            let selectedTopTabIndex = -1;
            let selectedSecondTabIndex = -1;
            let notifLink = {
                name: {
                    baseUrl: urls.BASE_COMMON_CLIENT_DASHBOARD,
                    urlParams: ['user_id'],
                }
            };
            let alertLink = {
                name: {
                    baseUrl: urls.BASE_COMMON_CLIENT_DASHBOARD,
                    urlParams: ['user_id'],
                }
            };
            let markAsActionButtons = [
                [{"type": "button", "display": "Mark Read", "active": true, callback: tableInnerLevelMiscCallback}],
                [{"type": "button", "display": "Mark Read", "active": true, callback: tableInnerLevelMiscCallback}]
            ]; 
            newTabConfig.forEach((tabEle, topTabIndex) => {
                if (tabEle.target == "notif") {
                    let links: ILinksRepr[] = [];
                    tabEle.children.forEach(() => {
                        links.push(notifLink);
                    })
                    tabEle.links = links;
                    tabEle.innerMiscHeaders = markAsActionButtons;
                }
                if (tabEle.target == "alert") {
                    let links: ILinksRepr[] = [];
                    tabEle.children.forEach(() => {
                        links.push(alertLink);
                    })
                    tabEle.links = links;
                }
                if (tabEle.active) {
                    selectedTopTabIndex = topTabIndex;
                    tabEle.children.forEach((childTabEle, secondTabIndex) => {
                        if (childTabEle.active) {
                            selectedSecondTabIndex = secondTabIndex;
                        }
                    })
                } else {
                    tabEle.children[0].active = true;
                }
            })
            setCurrTabTopLevelIndex(selectedTopTabIndex);
            setCurrTabSecondLevelIndex(selectedSecondTabIndex);
            let queryParams = {
                section: "notifications",
                notif_section: newTabConfig[selectedTopTabIndex].children[selectedSecondTabIndex].target,
                should_limit: JSON.stringify(false)
            }
            const notifRequestResponse = get(urls.CONFIG_ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchNotifResponseRepr>
            notifRequestResponse.then((notifResponse) => {
                setTabConfig(newTabConfig);
                setTabConfig((prevTabConfig) => {
                    let newTabConfig = [...prevTabConfig];
                    newTabConfig[selectedTopTabIndex].data[selectedSecondTabIndex] = [...notifResponse.data.notif[0].records as unknown as IDataRepr[]];
                    return newTabConfig;
                })
                setIsLoaded(true);
            })
        })

        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function tabCallback(prevTopLevelIndex: number, prevSecondLevelIndex: number, topLevelIndex: number, secondLevelIndex: number, contentName: string) {
        setCurrTabTopLevelIndex(topLevelIndex);
        setCurrTabSecondLevelIndex(secondLevelIndex);
        setTabConfig((prevTabConfig) => {
            let newTabConfig = [...prevTabConfig];
            let currTableData = newTabConfig[prevTopLevelIndex].data;
            if (currTableData != undefined) {
                let currData = currTableData[prevSecondLevelIndex];
                if (currData != undefined) {
                    currData.forEach((ele) => {
                        if (ele["table_checkbox"] != undefined) {
                            (ele["table_checkbox"] as ICheckboxRepr).checked = false;
                        }
                    })
                }
            }
            return newTabConfig;
        })
        setSelectedNotifId([]);
        let queryParams = {
            section: "notifications",
            notif_section: contentName,
            should_limit: JSON.stringify(false)
        }
        if (tabConfig[topLevelIndex].data[secondLevelIndex] == undefined) {
            const requestResponse = get(urls.CONFIG_ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchNotifResponseRepr>;
            requestResponse.then((response) => {
                setTabConfig((prevTabConfig) => {
                    let newTabConfig = [...prevTabConfig];
                    newTabConfig[topLevelIndex].data[secondLevelIndex] = [...response.data.notif[0].records as unknown as IDataRepr[]];
                    return newTabConfig;
                })
            })
        }
    }

    function inputCallback(index: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (accessKey == "table_checkbox" && typeof value == "boolean") {
            setTabConfig((prevTabConfig) => {
                let newTabConfig = [...prevTabConfig];
                let currTableData = newTabConfig[currTabTopLevelIndex].data;
                if (currTableData != undefined) {
                    let currData = currTableData[currTabSecondLevelIndex];
                    if (currData != undefined) {
                        (currData[index][accessKey] as ICheckboxRepr).checked = value;
                    }
                }
                return newTabConfig;
            })
            setSelectedNotifId((prevSelectedNotifId) => {
                let newSelectedNotifId = [...prevSelectedNotifId];
                let currTableData = tabConfig[currTabTopLevelIndex].data;
                if (currTableData != undefined) {
                    let currData = currTableData[currTabSecondLevelIndex];
                    if (currData != undefined) {
                        newSelectedNotifId = [...newSelectedNotifId, currData[index].id];
                    }
                }
                return newSelectedNotifId; 
            })
        }
    }

    function tableInnerLevelMiscCallback(button: string) {
        setTabConfig((prevTabConfig) => {
            setCurrTabTopLevelIndex((prevCurrTabTopLevelIndex) => {
                setCurrTabSecondLevelIndex((prevCurrTabSecondLevelIndex) => {
                    setSelectedNotifId((prevSelectedNotifId) => {
                        let queryParams = {
                            section: "notifications_clear"
                        }
                        let formData = new FormData();
                        formData.append("notif_ids", JSON.stringify(prevSelectedNotifId));
                        formData.append("section", prevTabConfig[prevCurrTabTopLevelIndex].children[prevCurrTabSecondLevelIndex].target);
                        const requestResponse = post(urls.CONFIG_ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
                        requestResponse.then(() => {
                            setTabConfig((prevTabConfig) => {
                                let newTabConfig = [...prevTabConfig];
                                let tableData = newTabConfig[prevCurrTabTopLevelIndex].data[prevCurrTabSecondLevelIndex];
                                if (tableData != undefined) {
                                    newTabConfig[prevCurrTabTopLevelIndex].data[prevCurrTabSecondLevelIndex] = tableData.filter((ele) => {
                                        return !(prevSelectedNotifId.includes(ele.id));
                                    })
                                }
                                return newTabConfig;
                            })
                            setSelectedNotifId([]);
                        })
                        return prevSelectedNotifId;
                    }) 
                    return prevCurrTabSecondLevelIndex;
                })
                return prevCurrTabTopLevelIndex;
            })
            return prevTabConfig;
        })
    }

    function getContent() {
        
        return <ContentBoxMultiTabTable hasSearch={true} title="Event" tabs={tabConfig} tabCallback={tabCallback}
            inputCallback={inputCallback} />
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix">
                {getContent()}
                <ScrollTop />
            </div>
        )

    }
}

export default NotificationTable;