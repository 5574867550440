import React from "react";
import $ from "jquery";
import { AxiosResponse } from "axios";

import ContentBoxTabTable from '../../../../../intelws_portal/bundles/ContentBoxTabTable';
import Loading from '../../../../../intelws_portal/constructs/elements/Loading';
import { IDataRepr, isICheckboxRepr, isIHtmlComponentReprArray } from "../../../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken, post } from "../../../../../intelws_portal/utils/backendInterface";

import Notes from './Notes';
import TodoList, { ITodoListRepr } from './TodoList';
import { IPageComponentProps } from "../../../../../declarations/common";
import { IMenuOptionsConfig } from "../../../../App";
import ClientCard from "./ClientCard";
import ClientOnboard from "./ClientOnboard";
import ContentBoxTable from "../../../../../intelws_portal/bundles/ContentBoxTable";
import { ITabRepr } from "../../../../../intelws_portal/constructs/elements/Tabs";
import AddButton from "../../../../../intelws_portal/constructs/elements/AddButton";
import { getBundledAlertsUI } from "../../../../../declarations/common_utils";
import ModalClientRefer from "../../../modal/ModalClientRefer";
import referralIcon from '../../../../../external/backPages/img/images/team-inactive.svg';

interface IMatchProps {
    userId: string
}

interface IFetchActiveEngagements extends AxiosResponse {
    data: {
        user_tracking_data?: IDataRepr[],
        task_tracking_data?: IDataRepr[],
        is_user: boolean
    }
}

interface IFetchAlert extends AxiosResponse {
    data: {
        data: IDataRepr[],
        tab_config?: ITabRepr[]
    }
}

interface IFetchTodo extends AxiosResponse {
    data: {
        data: ITodoListRepr[]
    }
}

interface IFetchNotes extends AxiosResponse {
    data: {
        notes: string
    }
}

interface IPostTodo extends AxiosResponse {
    data: {
        data: ITodoListRepr | { message: string }
    }
}

export interface IClientDashboardProps<T> extends IPageComponentProps<T> {
    mutator: {
        currentPage?: (currentPage: string) => void,
        getMenuOptionsConfig: () => IMenuOptionsConfig
    }
}

function isTodoListRepr(obj: any): obj is ITodoListRepr {
    let objAsITodoListRepr = obj as ITodoListRepr;
    return (
        objAsITodoListRepr != undefined &&
        objAsITodoListRepr.id != undefined &&
        objAsITodoListRepr.is_auto != undefined &&
        objAsITodoListRepr.is_completed != undefined &&
        objAsITodoListRepr.td_created_date != undefined &&
        objAsITodoListRepr.td_duedate != undefined &&
        objAsITodoListRepr.td_task != undefined)
}

function ClientDashboard(props: IClientDashboardProps<IMatchProps>) {
    const ENDPOINT = "api/clientdashboard/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isUser, setIsUser] = React.useState(false);
    const [activeEngagements, setActiveEngagements] = React.useState<(IDataRepr[] | undefined)[]>([undefined, undefined, undefined]);
    const [alertsTabConfig, setAlertsTabConfig] = React.useState<ITabRepr[]>([]);
    const [alerts, setAlerts] = React.useState<(IDataRepr[] | undefined)[]>([undefined, undefined]);
    const [clientMessageInput, setClientMessageInput] = React.useState("");
    const [teamMessageInput, setTeamMessageInput] = React.useState("");
    const [notesText, setNotesText] = React.useState("");
    const [todoList, setTodoList] = React.useState<ITodoListRepr[]>([]);
    const [activeEngagementsCurrTab, setActiveEngagementsCurrTab] = React.useState(0);

    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ClientDashboard");
        }
        let activeEngagementQueryParams = { user_id: props.match.params.userId, section: "active_engagements", cat: 0 };
        const activeEngagementsRequestResponse = get(ENDPOINT, cancelTokenSource.current.token, activeEngagementQueryParams) as Promise<IFetchActiveEngagements>;
        let todoQueryParams = { user_id: props.match.params.userId, section: "todo" };
        const todoRequestResponse = get(ENDPOINT, cancelTokenSource.current.token, todoQueryParams) as Promise<IFetchTodo>;
        let notesQueryParams = { user_id: props.match.params.userId, section: "client_notes" };
        const notesRequestResponse = get(ENDPOINT, cancelTokenSource.current.token, notesQueryParams) as Promise<IFetchNotes>;
        let alertQueryParams = { user_id: props.match.params.userId, section: "alerts", alert_type: "-" };
        const alertRequestResponse = get(ENDPOINT, cancelTokenSource.current.token, alertQueryParams) as Promise<IFetchAlert>;
        Promise.all([activeEngagementsRequestResponse, todoRequestResponse, notesRequestResponse, alertRequestResponse]).then(
            ([activeEngagementsResponse, todoResponse, notesResponse, alertResponse]) => {
                setIsUser(activeEngagementsResponse.data.is_user);
                setActiveEngagements((prevActiveEngagements) => {
                    let newActiveEngagements = [...prevActiveEngagements];
                    if (activeEngagementsResponse.data.user_tracking_data != undefined) {
                        newActiveEngagements[0] = activeEngagementsResponse.data.user_tracking_data;
                    } else if (activeEngagementsResponse.data.task_tracking_data != undefined) {
                        newActiveEngagements[0] = activeEngagementsResponse.data.task_tracking_data;
                    }
                    return newActiveEngagements;
                })
                setTodoList(todoResponse.data.data);
                setNotesText(notesResponse.data.notes);
                if (alertResponse.data.tab_config != undefined) {
                    setAlertsTabConfig(alertResponse.data.tab_config);
                    setAlerts(new Array(alertResponse.data.tab_config.length).fill(undefined));
                }
                setAlerts((prevAlerts) => {
                    let newAlerts = [...prevAlerts];
                    newAlerts[0] = alertResponse.data.data
                    return newAlerts;
                });
                setIsLoaded(true);
            })
    }, [])

    function inputCallback(rowIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        let formData = new FormData();
        let currentTable = activeEngagements[activeEngagementsCurrTab];
        if (currentTable != undefined) {
            formData.append("tracking_id", currentTable[rowIndex].id.toString());

            let queryParams = {}

            if (accessKey == "uploadCompleteCheckbox") {
                queryParams = { user_id: props.match.params.userId, section: "upload_complete" };
            }

            if (accessKey == "extensionCheckbox") {
                queryParams = { user_id: props.match.params.userId, section: "extension" };
            }

            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then(() => {
                setActiveEngagements((prevActiveEngagements) => {
                    let newActiveEngagements = [...prevActiveEngagements];
                    let currTable = newActiveEngagements[activeEngagementsCurrTab];
                    if (currTable != undefined) {
                        if (accessKey == "uploadCompleteCheckbox") {
                            let uploadCompleteCheckbox = currTable[rowIndex]["uploadCompleteCheckbox"];
                            if ((typeof uploadCompleteCheckbox != "string" && typeof uploadCompleteCheckbox != "number")) {
                                if (!isIHtmlComponentReprArray(uploadCompleteCheckbox) && isICheckboxRepr(uploadCompleteCheckbox)) {
                                    uploadCompleteCheckbox.checked = true;
                                    uploadCompleteCheckbox.disabled = true;
                                }
                            }
                        }
                        if (accessKey == "extensionCheckbox") {
                            let extensionCheckbox = currTable[rowIndex]["extensionCheckbox"];
                            if ((typeof extensionCheckbox != "string" && typeof extensionCheckbox != "number")) {
                                if (!isIHtmlComponentReprArray(extensionCheckbox) && isICheckboxRepr(extensionCheckbox)) {
                                    extensionCheckbox.checked = true;
                                    extensionCheckbox.disabled = true;
                                }
                            }
                        }
                    }
                    return newActiveEngagements;
                })
            })
        }
    }

    function activeEngagementsTabCallback(tab: string, index: number) {
        let stateUpdateIndex = -1;
        let queryParams = { user_id: props.match.params.userId, section: "active_engagements", cat: -1 };
        if (tab == "personal" && activeEngagements[0] == undefined) {
            queryParams.cat = 0;
            stateUpdateIndex = 0;
        } else if (tab == "business" && activeEngagements[1] == undefined) {
            queryParams.cat = 1;
            stateUpdateIndex = 1;
        } else if (tab == "projectactiveengagement" && activeEngagements[2] == undefined) {
            queryParams.cat = 2;
            stateUpdateIndex = 2;
        }
        setActiveEngagementsCurrTab(index);
        if (stateUpdateIndex != -1) {
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchActiveEngagements>;
            requestResponse.then((response) => {
                setActiveEngagements((prevActiveEngagements) => {
                    let newActiveEngagements = [...prevActiveEngagements];
                    if (response.data.user_tracking_data != undefined) {
                        newActiveEngagements[stateUpdateIndex] = response.data.user_tracking_data;
                    } else if (response.data.task_tracking_data != undefined) {
                        newActiveEngagements[stateUpdateIndex] = response.data.task_tracking_data;
                    }
                    return newActiveEngagements;
                })
            })
        }
    }

    function alertsTabCallback(tab: string, index: number) {
        let shouldExecute = false;
        if (alerts[index] == undefined) {
            shouldExecute = true;
        }
        if (shouldExecute) {
            let queryParams = {
                user_id: props.match.params.userId,
                section: "alerts",
                alert_type: tab
            }
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchAlert>;
            requestResponse.then((response) => {
                setAlerts((prevAlerts) => {
                    let newAlerts = [...prevAlerts];
                    newAlerts[index] = response.data.data;
                    return newAlerts;
                })
            })
        }
    }

    function todoCheckboxCallback(todoId: number, todoMovementMark: number, orderIndex: number) {
        let formData = new FormData();
        formData.append("todo_id", todoId.toString());
        formData.append("todo_movement_mark", todoMovementMark.toString());
        let queryParams = { user_id: props.match.params.userId, section: "todo_switch_state" };
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostTodo>;
        requestResponse.then((response) => {
            setTodoList((prevTodoList) => {
                let newTodoList = [...prevTodoList];
                let todoResponseData = response.data.data;
                if (isTodoListRepr(todoResponseData)) {
                    newTodoList[orderIndex] = todoResponseData;
                } else {
                    newTodoList.splice(orderIndex, 1);
                }
                return newTodoList;
            })
        })
    }

    function updateTodoList(data: ITodoListRepr) {
        setTodoList((prevTodoList) => {
            let newTodoList = [data, ...prevTodoList];
            return newTodoList;
        })
    }

    function notesUpdateCallback(newNotesValue?: string) {
        let formData = new FormData();
        if (newNotesValue != undefined) {
            formData.append("new_note", newNotesValue);
            let queryParams = {
                user_id: props.match.params.userId,
                section: "client_notes"
            }
            post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);

        }
    }

    function referUpdateCallback(success?: string[], fail?: string[]) {
        if (success != undefined) {
            setSuccess(success);
        }
        if (fail != undefined) {
            setFail(fail);
        }
    }

    function buildActiveEngagements() {
        let tabs = [
            { target: "Personal", active: true, display: "Individual" },
            { target: "Business", active: false, display: "Business" },
            { target: "ProjectActiveEngagement", active: false, display: "Projects" }
        ]

        let personalColumnNames = ["Tax Year", "Manager", "Employee", "Status", "Uploaded", "Extension"];
        let businessColumnNames = ["Business", "Tax Year", "Manager", "Employee", "Status", "Uploaded", "Extension"];
        let personalAccessKeys = ["ut_year", "ut_mgr_assigned_name", "ut_emp_assigned_name", "ut_current_status_name", "uploadCompleteCheckbox", "extensionCheckbox"];
        let businessAccessKeys = ["ut_bns_name", "ut_year", "ut_mgr_assigned_name", "ut_emp_assigned_name", "ut_current_status_name", "uploadCompleteCheckbox", "extensionCheckbox"];
        let projectAccessKeys = ["tt_task_name", "tt_bns_name", "tt_current_status_name"];
        let projectColumnNames = ["Project", "Business", "Status"];
        if (isUser) {
            personalColumnNames = ["Tax Year", "Status", "Uploaded", "Extension"];
            businessColumnNames = ["Businesss", "Tax Year", "Status", "Uploaded", "Extension"];
            personalAccessKeys = ["ut_year", "ut_current_status_name", "uploadCompleteCheckbox", "extensionCheckbox"];
            businessAccessKeys = ["ut_bns_name", "ut_year", "ut_current_status_name", "uploadCompleteCheckbox", "extensionCheckbox"];
        }
        let accessKeys = [personalAccessKeys, businessAccessKeys, projectAccessKeys];
        let columnNames = [personalColumnNames, businessColumnNames, projectColumnNames];

        /*
        console.log(activeEngagements);
        accessKeys.forEach((value, index) => {
            let data = activeEngagements[index];
            if (data != undefined && data.length > 0) {
                value.forEach((currAccessKey) => {
                    console.log(currAccessKey + ": " + data[0][currAccessKey] + "\n");
                })
            }
        })
        */
        return <ContentBoxTabTable inputCallback={inputCallback} tabs={tabs} contents={activeEngagements} columnNames={columnNames}
            accessKeys={accessKeys} title="Active Engagements" hasSearch={false} tabCallback={activeEngagementsTabCallback} />
    }

    function buildAlerts() {
        let columnNames = ["Alert", "Created Time"];
        let accessKeys = ["al_info", "al_created_time"];

        let finalColumnNames: string[][] = [];
        let finalAccessKeys: string[][] = [];
        alertsTabConfig.forEach((ele) => {
            finalColumnNames.push(columnNames);
            finalAccessKeys.push(accessKeys);
        })
        return <ContentBoxTabTable tabs={alertsTabConfig} contents={alerts} columnNames={finalColumnNames} accessKeys={finalAccessKeys}
            title={"Alerts"} hasSearch={false} hasRecords={false} tabCallback={alertsTabCallback} />
    }

    function buildTodo() {
        if (props.mutator.getMenuOptionsConfig != undefined) {
            return <TodoList todoList={todoList} userId={parseInt(props.match.params.userId)} editMode={props.mutator.getMenuOptionsConfig().todo_edit as boolean}
                todoCheckboxCallback={todoCheckboxCallback} mutator={updateTodoList} />
        }
    }

    function buildReferral() {
        if (props.mutator.getMenuOptionsConfig().is_user != undefined && props.mutator.getMenuOptionsConfig().is_user) {
            return <AddButton buttonValue={<img src={referralIcon} className="active" alt="" style={{width:"80%", paddingLeft: 10 }}/>}  toolTipText={"Refer Friend"} onClick={() => $("#clientReferralModal").modal("show")} />
        }
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper">
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                <ModalClientRefer mutator={referUpdateCallback} userId={props.match.params.userId} />
                <div className="row">
                    {(() => {
                        if (props.mutator.getMenuOptionsConfig().notes) {
                            return (
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <Notes text={notesText} updateCallback={notesUpdateCallback} />
                                    <div style={{ paddingTop: "20px" }}>
                                        {buildActiveEngagements()}
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <React.Fragment>
                                <div className="heading-box">
                                    <div className="col-lg-11">
                                        <ClientCard userId={parseInt(props.match.params.userId)} clientContext={true} />
                                    </div>
                                    <div className="col-lg-1" style={{paddingBottom: 36, paddingLeft: 0}}>
                                        {buildReferral()}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <ClientOnboard />
                                </div>

                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    {buildActiveEngagements()}
                                </div>
                            </React.Fragment>
                        )
                    })()}
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        {buildTodo()}
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        {buildAlerts()}
                    </div>

                </div>
            </div>
        )
    }
}

export default ClientDashboard;
