import React from "react";
import { AxiosResponse } from "axios";


import ContentBoxTabTable from "../../../../intelws_portal/bundles/ContentBoxTabTable";
import AddButton from "../../../../intelws_portal/constructs/elements/AddButton";
import ScrollTop from "../../../../intelws_portal/constructs/elements/ScrollTop";
import Loading from '../../../../intelws_portal/constructs/elements/Loading';
import { IDataRepr, IDateRepr, IDropdownRepr } from "../../../../intelws_portal/constructs/elements/Table";
import { ITabRepr } from "../../../../intelws_portal/constructs/elements/Tabs";
import { get, getCancelToken, post } from "../../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../../declarations/common";
import ModalHistoryStatus from "../../modal/ModalHistoryStatus";

interface IEmployeeDropdownRepr extends IDropdownRepr {
    mgr_index: number 
}

interface ITableData extends IDataRepr {
    ut_mgr_assigned_name: IDropdownRepr,
    ut_emp_assigned_name: IDropdownRepr,
    ut_eg_status: IDropdownRepr,
    ut_current_status_name: IDropdownRepr,
    ut_emp_assigned_combination: IEmployeeDropdownRepr[],
    ut_due_date: IDateRepr,
}

interface ITabConfig {
    tab_config: ITabRepr[],
    is_edit: boolean,
    access_keys: string[][],
    column_names: string[][]
}

interface IFetchConfig extends AxiosResponse {
    data: ITabConfig
}

interface IFetchStatus extends AxiosResponse {
    data: {
        data: IDataRepr[]
    }
}

interface IFetchStatusEdit extends AxiosResponse {
    data: {
        data: ITableData[]
    }
}

interface IPostStatus extends AxiosResponse {
    data: {
        data: {
            personal: IDataRepr[];
            business: IDataRepr[];
        }
    }
}

interface IMatchProps {
    userId: string
}

function ClientStatus(props: IPageComponentProps<IMatchProps>) {
    const ENDPOINT = "api/clientdashboard/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [currTabIndex, setCurrTabIndex] = React.useState(0);
    const [historyStatusId, setHistoryStatusId] = React.useState<number | undefined>(undefined);
    const [statusTableData, setStatusTableData] = React.useState<(IDataRepr[] | undefined)[]>([undefined, undefined]);
    const [statusEditTableData, setStatusEditTableData] = React.useState<(ITableData[] | undefined)[]>([undefined, undefined]);
    const [config, setConfig] = React.useState<ITabConfig | undefined>(undefined);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ClientStatus");
        }
        let configQueryParams = { user_id: props.match.params.userId, section: "status_tab_config" }
        const configRequestResponse = get(ENDPOINT, cancelTokenSource.current.token, configQueryParams) as Promise<IFetchConfig>;
        configRequestResponse.then((configResponse) => {
            setConfig(configResponse.data);
            setStatusTableData(new Array(configResponse.data.tab_config.length));
            setStatusEditTableData(new Array(configResponse.data.tab_config.length));
            let statusQueryParams = { user_id: props.match.params.userId, section: "status", 
                cat: configResponse.data.tab_config[0].target, is_edit: false }
            const statusRequestResponse = get(ENDPOINT, cancelTokenSource.current.token, statusQueryParams) as Promise<IFetchStatus>;
            statusRequestResponse.then((statusResponse) => {
                setStatusTableData((prevStatusTableData) => {
                    let newStatusTableData = [...prevStatusTableData];
                    newStatusTableData[0] = statusResponse.data.data;
                    return newStatusTableData;
                })
                setIsLoaded(true);
            })
        })
    }, [])

    function tabCallback(tabName: string, index: number) {
        setCurrTabIndex(index);
        let shouldExecute = false;
        if (isEdit && statusEditTableData[index] == undefined) {
            shouldExecute = true;
        } else if (!isEdit && statusTableData[index] == undefined) {
            shouldExecute = true;
        }
        if (shouldExecute) {
            let queryParams = {
                user_id: props.match.params.userId, section: "status",
                cat: tabName, is_edit: isEdit
            }
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchStatusEdit>;
            requestResponse.then((response) => {
                if (isEdit) {
                    setStatusEditTableData((prevStatusEditTableData) => {
                        let newStatusEditTableData = [...prevStatusEditTableData];
                        newStatusEditTableData[index] = response.data.data;
                        return newStatusEditTableData;
                    })
                } else {
                    setStatusTableData((prevStatusTableData) => {
                        let newStatusTableData = [...prevStatusTableData];
                        newStatusTableData[index] = response.data.data;
                        return newStatusTableData;
                    })
                }
            })
        }
    }

    function inputCallback(rowIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (accessKey == "ut_mgr_assigned_name") {
            setStatusEditTableData((prevStatusEditTableData) => {
                let newStatusEditTableData = [...prevStatusEditTableData];
                let currTable = newStatusEditTableData[currTabIndex];
                if (currTable != undefined && typeof value == "string") {
                    let newDropdown = currTable[rowIndex].ut_emp_assigned_combination.find((ele) => {
                        return parseInt(value) == ele.mgr_index;
                    })
                    currTable[rowIndex].ut_mgr_assigned_name.value = parseInt(value);
                    currTable[rowIndex].ut_emp_assigned_name = newDropdown as IDropdownRepr;
                }
                return newStatusEditTableData;
            })
        } else if (accessKey == "ut_emp_assigned_name" || accessKey == "ut_current_status_name" || accessKey == "ut_due_date" || accessKey == "ut_eg_status") {
            setStatusEditTableData((prevStatusEditTableData) => {
                let newStatusEditTableData = [...prevStatusEditTableData];
                let currTable = newStatusEditTableData[currTabIndex];
                if (currTable != undefined && typeof value == "string") {
                    if (accessKey == "ut_due_date" || accessKey == "ut_eg_status") {
                        currTable[rowIndex][accessKey].value = value;
                    } else {
                        currTable[rowIndex][accessKey].value = parseInt(value);
                    }
                }
                return newStatusEditTableData;
            })
        } else if (accessKey == "ut_action" && typeof value == "string" && value == "history_status") {
            let currTable = statusEditTableData[currTabIndex] as (IDataRepr[] | undefined);
            if (!isEdit) {
                currTable = statusTableData[currTabIndex];
            }
            if (currTable != undefined) {
                setHistoryStatusId(currTable[rowIndex].id);
            }
        }
    }

    function globalButtonCallback(type: "Edit" | "Update") {
        if (type == "Edit" && config != undefined) {
            let queryParams = {
                user_id: props.match.params.userId,
                section: "status",
                cat: config.tab_config[currTabIndex].target,
                is_edit: true
            }
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchStatusEdit>; 
            requestResponse.then((response) => {
                setStatusEditTableData((prevStatusEditTableData) => {
                    let newStatusEditTableData = [...prevStatusEditTableData];
                    newStatusEditTableData[currTabIndex] = response.data.data;
                    return newStatusEditTableData;
                })
                setIsEdit(true);
            })
        } else if (type == "Update") {
            let queryParams = {
                user_id: props.match.params.userId,
                section: "status"
            }
            let formData = new FormData();
            let edit_data = {
                personal: statusEditTableData[0],
                business: statusEditTableData[1]
            }
            formData.append("edit_data", JSON.stringify(edit_data));
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostStatus>;
            requestResponse.then((response) => {
                let personalStatusTableData = response.data.data.personal;
                let businessStatusTableData = response.data.data.business;

                if (personalStatusTableData != null) {
                    personalStatusTableData.forEach((ele) => {
                        if (ele == undefined || ele == null) {
                            ele = undefined as any;
                        }
                    })
                }
                if (businessStatusTableData != null) {
                    businessStatusTableData.forEach((ele) => {
                        if (ele == undefined || ele == null) {
                            ele = undefined as any;
                        }
                    })
                }

                setStatusTableData((prevStatusTableData) => {
                    let newStatusTableData = [...prevStatusTableData];
                    newStatusTableData[0] = personalStatusTableData;
                    newStatusTableData[1] = businessStatusTableData;
                    return newStatusTableData;
                })
                setIsEdit(false);
            })
        }
    }

    function buildStatusTable() {
        if (config != undefined) {

            let buttonShow: JSX.Element | undefined = undefined;
            let tableData: (IDataRepr[] | undefined)[] = [];
            if (isEdit) {
                buttonShow = <AddButton buttonValue={<i className="fa fa-floppy-o"></i>} onClick={() => globalButtonCallback("Update")} />
                tableData = statusEditTableData;
            } else {
                buttonShow = <AddButton buttonValue={<i className="fa fa-edit"></i>} onClick={() => globalButtonCallback("Edit")} />
                tableData = statusTableData;
            }
            if (config != undefined && !config.is_edit) {
                buttonShow = undefined;
            }
            /*
            config.access_keys.forEach((value, index) => {
                let data = tableData[index];
                if (data != undefined && data.length > 0) {
                    console.log("Tab " + index);
                    value.forEach((currAccessKey) => {
                        console.log(currAccessKey + ": " + data[0][currAccessKey]);
                    })
                }
            })
            */
            return (
                <div className="body-content-wrapper clearfix" style={{ paddingTop: "16px" }}>
                    <ContentBoxTabTable title="Tax Status" hasSearch={true} hasRecords={true} columnNames={config.column_names} 
                        accessKeys={config.access_keys} tabs={config.tab_config} contents={tableData} tabCallback={tabCallback}
                        miscHeaders={buttonShow} inputCallback={inputCallback} />
                </div>
            )
        }
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div>
                <div className="container-fluid">
                    {buildStatusTable()}
                </div>
                <ModalHistoryStatus userId={parseInt(props.match.params.userId)} trackingId={historyStatusId} 
                    closeCallback={() => setHistoryStatusId(undefined)} />
                <ScrollTop />
            </div>
        )
    }
}

export default ClientStatus;
