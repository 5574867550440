import React from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import ModalCheckbox from "../../../intelws_portal/constructs/modal/ModalCheckbox";
import ModalDatePicker from '../../../intelws_portal/constructs/modal/ModalDatePicker';
import ModalDropDown from '../../../intelws_portal/constructs/modal/ModalDropDown';
import ModalInputText from '../../../intelws_portal/constructs/modal/ModalInputText';
import ModalFooter from '../../../intelws_portal/constructs/modal/ModalFooter';
import ModalHeader from '../../../intelws_portal/constructs/modal/ModalHeader';
import { IDataRepr } from '../../../intelws_portal/constructs/elements/Table';
import { parseQueryResponseOnFail, parseQueryResponseOnSuccess, usePickPropsIfNotNull } from '../../../declarations/common_utils';
import { get, getCancelToken, post } from '../../../intelws_portal/utils/backendInterface';
import { AxiosResponse } from 'axios';
import ModalMultiDropDown from '../../../intelws_portal/constructs/modal/ModalMultiDropDown';

export interface IFetchBusinessConfigRepr {
    bk_freq_options?: string[],
    bk_freq_values?: number[],
    pr_freq_options?: string[],
    pr_freq_values?: number[],
    st_freq_options?: string[],
    st_freq_values?: number[],
    tp_freq_options?: string[],
    tp_freq_values?: number[],
    days_options?: string[],
    days_values?: number[],
    months_options?: string[],
    months_values?: number[]
    us_states: {
        state_abbr: string
    }[],
    bns_types: {
        id: number,
        bns_type_name: string,
    }[],
    emps?: {
        id: number,
        emp_full_name: string
    }[],
    is_user: boolean
}

export interface IFetchBusinessConfig extends AxiosResponse {
    data: IFetchBusinessConfigRepr
}

export interface IPostBusinessAdd extends AxiosResponse {
    data: {
        data: IDataRepr
    }
}

export interface IModalBusinessProps {
    userId: number,
    mutator: (data?: IDataRepr, success?: string[], fail?: string[]) => void,
    bnsOperCallback: (data: IDataRepr, oper: string) => void,
    onClose?: () => void,
    //Modal edit attr
    bnsId?: number,
    bnsName?: string,
    bnsEIN?: string,
    bnsType?: number,
    incorpState?: string,
    incorpDate?: string,
    bk?: boolean,
    bkFreq?: number,
    bkMonth?: number,
    bkDays?: number,
    bkEmps?: number[],
    pr?: boolean,
    prFreq?: number,
    prMonth?: number,
    prDays?: number,
    prEmps?: number[]
    st?: boolean,
    stFreq?: number,
    stMonth?: number,
    stDays?: number,
    stEmps?: number[],
    tp?: boolean,
    tpFreq?: number,
    tpMonth?: number,
    tpDays?: number,
    tpEmps?: number[]
}

function isString(obj: any): obj is string {
    return typeof obj == "string";
}

function isNumber(obj: any): obj is number {
    return typeof obj == "number";
}

function isBoolean(obj: any): obj is boolean {
    return typeof obj == "boolean";
}

function ModalBusiness(props: IModalBusinessProps) {
    const CLIENT_DASHBOARD_ENDPOINT = "api/clientdashboard/";
    const [bnsId, setBnsId] = usePickPropsIfNotNull(props.bnsId, -1);
    const [bnsName, setBnsName] = usePickPropsIfNotNull(props.bnsName, "");
    const [bnsEIN, setBnsEIN] = usePickPropsIfNotNull(props.bnsEIN, "");
    const [bnsType, setBnsType] = usePickPropsIfNotNull(props.bnsType, 0);
    const [incorpState, setIncorpState] = usePickPropsIfNotNull(props.incorpState, "");
    const [incorpDate, setIncorpDate] = usePickPropsIfNotNull(props.incorpDate, "");
    const [bk, setBk] = usePickPropsIfNotNull(props.bk, false);
    const [bkFreq, setBkFreq] = usePickPropsIfNotNull(props.bkFreq, -1);
    const [bkMonth, setBkMonth] = usePickPropsIfNotNull(props.bkMonth, -1);
    const [bkDays, setBkDays] = usePickPropsIfNotNull(props.bkDays, -1);
    const [bkEmps, setBkEmps] = usePickPropsIfNotNull(props.bkEmps, []);
    const [pr, setPr] = usePickPropsIfNotNull(props.pr, false);
    const [prFreq, setPrFreq] = usePickPropsIfNotNull(props.prFreq, -1);
    const [prMonth, setPrMonth] = usePickPropsIfNotNull(props.prMonth, -1);
    const [prDays, setPrDays] = usePickPropsIfNotNull(props.prDays, -1);
    const [prEmps, setPrEmps] = usePickPropsIfNotNull(props.prEmps, []);
    const [st, setSt] = usePickPropsIfNotNull(props.st, false);
    const [stFreq, setStFreq] = usePickPropsIfNotNull(props.stFreq, -1);
    const [stMonth, setStMonth] = usePickPropsIfNotNull(props.stMonth, -1);
    const [stDays, setStDays] = usePickPropsIfNotNull(props.stDays, -1);
    const [stEmps, setStEmps] = usePickPropsIfNotNull(props.stEmps, []);
    const [tp, setTp] = usePickPropsIfNotNull(props.tp, false);
    const [tpFreq, setTpFreq] = usePickPropsIfNotNull(props.tpFreq, -1);
    const [tpMonth, setTpMonth] = usePickPropsIfNotNull(props.tpMonth, -1);
    const [tpDays, setTpDays] = usePickPropsIfNotNull(props.tpDays, -1);
    const [tpEmps, setTpEmps] = usePickPropsIfNotNull(props.tpEmps, []);

    const [isUser, setIsUser] = React.useState(false);
    const [bnsTypeOptions, setBnsTypeOptions] = React.useState<string[]>([]);
    const [bnsTypeValues, setBnsTypeValues] = React.useState<number[]>([]);
    const [incorpStateOptions, setIncorpStateOptions] = React.useState<string[]>([]);
    const [bkFreqOptions, setBkFreqOptions] = React.useState<string[]>([]);
    const [bkFreqValues, setBkFreqValues] = React.useState<number[]>([]);
    const [prFreqOptions, setPrFreqOptions] = React.useState<string[]>([]);
    const [prFreqValues, setPrFreqValues] = React.useState<number[]>([]);
    const [stFreqOptions, setStFreqOptions] = React.useState<string[]>([]);
    const [stFreqValues, setStFreqValues] = React.useState<number[]>([]);
    const [tpFreqOptions, setTpFreqOptions] = React.useState<string[]>([]);
    const [tpFreqValues, setTpFreqValues] = React.useState<number[]>([]);
    const [daysOptions, setDaysOptions] = React.useState<string[]>([]);
    const [daysValues, setDaysValues] = React.useState<number[]>([]);
    const [monthOptions, setMonthOptions] = React.useState<string[]>([]);
    const [monthValues, setMonthValues] = React.useState<number[]>([]);
    const [empOptions, setEmpOptions] = React.useState<string[]>([]);
    const [empValues, setEmpValues] = React.useState<number[]>([]);
    const [disable, setDisable] = React.useState(true);
    const [isbnsnameStartwithspace, setisbnsnameStartwithspace] = React.useState("");
    const [EinLength, setEinLength] = React.useState(props.bnsEIN ? props.bnsEIN.length : 0);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        const requestResponse = get(CLIENT_DASHBOARD_ENDPOINT, cancelTokenSource.current.token, { section: "business_config" }) as Promise<IFetchBusinessConfig>;
        requestResponse.then((response) => {
            let localIncorpStateOptions: string[] = [];
            let localBnsTypeOptions: string[] = [];
            let localBnsTypeValues: number[] = [];
            let localEmpOptions: string[] = [];
            let localEmpValues: number[] = [];
            response.data.us_states.forEach((ele) => {
                localIncorpStateOptions.push(ele.state_abbr);
            })
            response.data.bns_types.forEach((ele) => {
                localBnsTypeOptions.push(ele.bns_type_name);
                localBnsTypeValues.push(ele.id);
            })
            if (response.data.emps != null) {
                response.data.emps.forEach((ele) => {
                    if (ele != undefined) {
                        localEmpOptions.push(ele.emp_full_name);
                        localEmpValues.push(ele.id);
                    }
                })
            }
            if (response.data.bk_freq_options != null) {
                setBkFreqOptions(response.data.bk_freq_options);
            }
            if (response.data.bk_freq_values != null) {
                setBkFreqValues(response.data.bk_freq_values);
                setBkFreq(response.data.bk_freq_values[0]);
            }

            if (response.data.pr_freq_options != null) {
                setPrFreqOptions(response.data.pr_freq_options);
            }
            if (response.data.pr_freq_values != null) {
                setPrFreqValues(response.data.pr_freq_values);
                setPrFreq(response.data.pr_freq_values[0]);
            }
            if (response.data.st_freq_options != null) {
                setStFreqOptions(response.data.st_freq_options);
            }
            if (response.data.st_freq_values != null) {
                setStFreqValues(response.data.st_freq_values);
                setStFreq(response.data.st_freq_values[0]);
            }
            if (response.data.tp_freq_options != null) {
                setTpFreqOptions(response.data.tp_freq_options);
            }
            if (response.data.tp_freq_values != null) {
                setTpFreqValues(response.data.tp_freq_values);
                setTpFreq(response.data.tp_freq_values[0]);
            }
            if (response.data.days_options != null) {
                setDaysOptions(response.data.days_options);
            }
            if (response.data.days_values != null) {
                setDaysValues(response.data.days_values);
                setBkDays(response.data.days_values[0]);
                setPrDays(response.data.days_values[0]);
                setStDays(response.data.days_values[0]);
                setTpDays(response.data.days_values[0]);
            }
            if (response.data.months_options != null) {
                setMonthOptions(response.data.months_options);
            }
            if (response.data.months_values != null) {
                setMonthValues(response.data.months_values);
                setBkMonth(response.data.months_values[0]);
                setPrMonth(response.data.months_values[0]);
                setStMonth(response.data.months_values[0]);
                setTpMonth(response.data.months_values[0]);
            }
            setIncorpStateOptions(localIncorpStateOptions);
            setBnsTypeOptions(localBnsTypeOptions);
            setBnsTypeValues(localBnsTypeValues);
            setEmpOptions(localEmpOptions);
            setEmpValues(localEmpValues);
            setBnsType(localBnsTypeValues[0]);
            setIncorpState(localIncorpStateOptions[0]);
            setIsUser(response.data.is_user);
        })
        return () => {
            cancelTokenSource.current.cancel();
            $("#BusinessAddModal").off("hidden.bs.modal");
        }
    }, [])
    
    React.useEffect(() => {
        $("#BusinessAddModal").on("hidden.bs.modal", clearForm)
    }, [bnsTypeValues, incorpStateOptions, bkFreqValues, prFreqValues, stFreqValues, tpFreqValues, isUser])

    React.useEffect(() => {
        let newDisable = true;
        if (incorpDate != "" && bnsName != "" && bnsEIN != "" && validateBnsEIN() && validateBk() && validatePr() && validateSt() && validateTp()) {
            newDisable = false;
        }
        setDisable(newDisable);

    }, [incorpDate, bnsName, bnsEIN, bk, bkFreq, bkMonth, bkDays, pr, prFreq, prMonth, prDays, st, stFreq, stMonth, stDays, tp, tpFreq, tpMonth, tpDays, ])

    function clearForm() {
        setBnsName("");
        setBnsEIN("");
        setBnsType(bnsTypeValues[0]);
        setIncorpState(incorpStateOptions[0]);
        setIncorpDate("");
        if (!isUser) {
            setBk(false);
            setBkFreq(bkFreqValues[0]);
            setPr(false);
            setPrFreq(prFreqValues[0]);
            setSt(false);
            setStFreq(stFreqValues[0]);
            setTp(false);
            setTpFreq(tpFreqValues[0]);
        }
        if (props.onClose != undefined) {
            props.onClose();
        }
    }

    function isalphanum(value: string){
        if (!(value.match(/^[a-z0-9A-Z\s]+$/) || value == "")){
            setisbnsnameStartwithspace("please enter only alphanumeric value")
            return(false)
        }
        else{
            setisbnsnameStartwithspace("")
            return(true)
        }
    }
    
    function isstartwithspace(value: string){
        if(value == " " ){
            setisbnsnameStartwithspace("please start business name with alphanumeric value")
            return(false)
        }
        else{
            setisbnsnameStartwithspace("")
            return(true)
        }
    }
    function formChange(field: "bnsName" | "bnsEIN" | "bnsType" | "incorpState" | "incorpDate" | "bk" | "bkFreq" | "pr" | "prFreq" | "st" | "stFreq" | "tp" | "tpFreq",
        value: boolean | number | string) {
        if (field == "bnsName" && isString(value) && isalphanum(value) && isstartwithspace(value)) {
            setBnsName(value);
        } else if(field == "bnsEIN" && isValidEIN(String(value)) && isString(value)) {
            setEinLength(bnsEIN.length)
            setBnsEIN(value);
            if (bnsEIN.length == 2 && EinLength < bnsEIN.length){
                setEinLength(bnsEIN.length)
                setBnsEIN(bnsEIN + "-")
            }
        } else if (field == "bnsType" && isNumber(value)) {
            setBnsType(value);
        } else if (field == "incorpState" && isString(value)) {
            setIncorpState(value);
        } else if (field == "incorpDate" && isString(value)) {
            setIncorpDate(value);
        } else if (field == "bk" && isBoolean(value)) {
            setBk(value);
            setBkFreq(-1);
            setBkDays(daysValues[0]);
            setBkMonth(monthValues[0]);
            setBkEmps([]);
        } else if (field == "bkFreq" && isNumber(value)) {
            setBkFreq(value);
            setBkDays(daysValues[0]);
            setBkMonth(monthValues[0]);
            setBkEmps([]);
        } else if (field == "pr" && isBoolean(value)) {
            setPr(value);
            setPrFreq(-1);
            setPrDays(daysValues[0]);
            setPrMonth(monthValues[0]);
            setPrEmps([])
        } else if (field == "prFreq" && isNumber(value)) {
            setPrFreq(value);
            setPrDays(daysValues[0]);
            setPrMonth(monthValues[0]);
            setPrEmps([])
        } else if (field == "st" && isBoolean(value)) {
            setSt(value);
            setStFreq(-1);
            setStDays(daysValues[0]);
            setStMonth(monthValues[0]);
            setStEmps([])
        } else if (field == "stFreq" && isNumber(value)) {
            setStFreq(value);
            setStDays(daysValues[0]);
            setStMonth(monthValues[0]);
            setStEmps([])
        } else if (field == "tp" && isBoolean(value)) {
            setTp(value);
            setTpFreq(-1);
            setTpDays(daysValues[0]);
            setTpMonth(monthValues[0]);
            setTpEmps([]);
        } else if (field == "tpFreq" && isNumber(value)) {
            setTpFreq(value);
            setTpDays(daysValues[0]);
            setTpMonth(monthValues[0]);
            setTpEmps([]);
        }
    }

    function validateBusinessService(serviceFreq: number, serviceMonth: number, serviceDays: number) {
        if (serviceFreq == 1) {
            //Monthly
            if (serviceDays != -1) {
                return true;
            }
        } else if (serviceFreq == 2 || serviceFreq == 3) {
            //Quarterly/Yearly
            if (serviceDays != -1 && serviceMonth != -1) {
                return true;
            }
        }
        return false;
    }

    function validateBnsEIN(){
        if (bnsEIN.length == 10){
            return true;
        }
        return false;
    }
    function validateBk() {
        if (!bk) {
            return true;
        }
        return validateBusinessService(bkFreq, bkMonth, bkDays)
    }

    function validatePr() {
        if (!pr) {
            return true;
        }
        return validateBusinessService(prFreq, prMonth, prDays)
    }

    function validateSt() {
        if (!st) {
            return true;
        }
        return validateBusinessService(stFreq, stMonth, stDays)
    }

    function validateTp() {
        if (!tp) {
            return true;
        }
        return validateBusinessService(tpFreq, tpMonth, tpDays)
    }

    function isValidEIN(value: string) {
        if (((value.match(/^[0-9 -]+$/)) || value == "") && value.length <= 10){
            return true
        }
        return false
    }

    function formSubmit() {
        console.log(bnsEIN);
        let ein = bnsEIN;
        ein = ein.replace("-","");
        let formData = new FormData();
        formData.append("bns_name", bnsName);
        formData.append("bns_ein", ein);
        formData.append("bns_type", bnsType.toString());
        formData.append("bns_incorp_state", incorpState);
        formData.append("bns_incorp_date", incorpDate);
        if (bnsId > -1) {
            formData.append("bns_id", bnsId.toString());
        }
        if (bk && validateBusinessService(bkFreq, bkMonth, bkDays)) {
            formData.append("is_bk", bk.toString());
            formData.append("bk_sched", bkFreq.toString());
            formData.append("bk_month_num", bkMonth.toString());
            formData.append("bk_day_num", bkDays.toString());
            if (bkEmps.length > 0) {
                formData.append("bk_emps", JSON.stringify(bkEmps));
            }
        }
        if (pr && validateBusinessService(prFreq, prMonth, prDays)) {
            formData.append("is_pr", pr.toString());
            formData.append("pr_sched", prFreq.toString());
            formData.append("pr_month_num", prMonth.toString());
            formData.append("pr_day_num", prDays.toString());
            if (prEmps.length > 0) {
                formData.append("pr_emps", JSON.stringify(prEmps));
            }
        }
        if (st && validateBusinessService(stFreq, stMonth, stDays)) {
            formData.append("is_st", st.toString());
            formData.append("st_sched", stFreq.toString());
            formData.append("st_month_num", stMonth.toString());
            formData.append("st_day_num", stDays.toString());
            if (stEmps.length > 0) {
                formData.append("st_emps", JSON.stringify(stEmps));
            }
        }
        if (tp && validateBusinessService(tpFreq, tpMonth, tpDays)) {
            formData.append("is_tp", tp.toString());
            formData.append("tp_sched", tpFreq.toString());
            formData.append("tp_month_num", tpMonth.toString());
            formData.append("tp_day_num", tpDays.toString());
            if (tpEmps.length > 0) {
                formData.append("tp_emps", JSON.stringify(tpEmps));
            }
        }
        let queryParams = {
            section: "business",
            user_id: -1
        }
        if (!Number.isNaN(props.userId)) {
            queryParams.user_id = props.userId;
        }
        console.log(formData.get("bns_ein"));
        
        const requestResponse = post(CLIENT_DASHBOARD_ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostBusinessAdd>;
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.mutator(response.data.data, success, fail);
            props.bnsOperCallback(response.data.data, "add");
        }).catch((error) => {
            let [success, fail] = parseQueryResponseOnFail(error);
            props.mutator(undefined, success, fail);
        }).finally(() => {
            $("#BusinessAddModal").modal("hide");
        })
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal custom-small text-center " id="BusinessAddModal" aria-labelledby="BusinessAddModal" aria-hidden="true">
                <div className="modal-dialog text-left modal-xl" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Create Business"} />
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-6">
                                <ModalInputText value={bnsName} title={"Name"} isinvalidmsg={isbnsnameStartwithspace}
                                onChange={(e) => formChange("bnsName", e.target.value)} borderBottom required />
                                </div>
                                <div className="col-6">
                                <ModalInputText id={"bnsEIN"} value={bnsEIN} title={"EIN"}
                                onChange={(e) => formChange("bnsEIN", e.target.value)} borderBottom required />
                                </div>
                            </div>
                             <br />
                            <div className="row">
                                <div className="col-4">
                                    <ModalDropDown value={bnsType} options={bnsTypeOptions}
                                        values={bnsTypeValues}
                                        onChange={(e) => formChange("bnsType", parseInt(e.target.value))} borderBottom />
                                </div>
                                <div className="col-4">
                                    <ModalDropDown value={incorpState} onChange={(e) => formChange("incorpState", e.target.value)}
                                        options={incorpStateOptions} borderBottom />
                                </div>
                                <div className="col-4" style={{ marginTop: "-7px" }}>
                                    <ModalDatePicker value={incorpDate} onChange={(e) => formChange("incorpDate", e.target.value)} required />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-3">
                                    {(() => {
                                        if (bkFreqOptions.length > 0 && !isUser) {
                                            return (
                                                <React.Fragment >
                                                    <ModalCheckbox label={"Book-keeping"} checked={bk}
                                                        checkboxCallback={(e) => formChange("bk", e.target.checked)} /><br />
                                                    {(() => {
                                                        if (bk) {
                                                            return (
                                                                <ModalDropDown value={bkFreq} options={bkFreqOptions} values={bkFreqValues}
                                                                    onChange={(e) => formChange("bkFreq", parseInt(e.target.value))} required borderBottom />
                                                            )
                                                        }
                                                    })()}<br />
                                                    {(() => {
                                                        if (bkFreq == 1) {
                                                            // Monthly
                                                            return (
                                                                <ModalDropDown options={daysOptions} values={daysValues} value={bkDays}
                                                                    onChange={(e) => setBkDays(parseInt(e.target.value))} required borderBottom />
                                                            )
                                                        } else if (bkFreq == 2 || bkFreq == 3) {
                                                            // Year/Quarterly
                                                            return (
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <ModalDropDown options={monthOptions} values={monthValues} value={bkMonth}
                                                                            onChange={(e) => setBkMonth(parseInt(e.target.value))} required borderBottom />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <ModalDropDown options={daysOptions} values={daysValues} value={bkDays}
                                                                            onChange={(e) => setBkDays(parseInt(e.target.value))} required borderBottom />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })()}<br />
                                                    {(() => {
                                                        if (bkFreq != -1) {
                                                            return (
                                                                <ModalMultiDropDown options={empOptions} values={empValues} value={bkEmps}
                                                                    onChange={(e) => setBkEmps(
                                                                        Array.from(e.target.selectedOptions, options => parseInt(options.value)))} />
                                                            )
                                                        }
                                                    })()}<br />
                                                </React.Fragment>
                                            )
                                        }
                                    })()}
                                </div>
                                <div className="col-3">
                                    {(() => {
                                        if (prFreqOptions.length > 0 && !isUser) {
                                            return (
                                                <React.Fragment >
                                                    <ModalCheckbox label={"Payroll"} checked={pr}
                                                        checkboxCallback={(e) => formChange("pr", e.target.checked)} /><br />
                                                    {(() => {
                                                        if (pr) {
                                                            return (
                                                                <ModalDropDown value={prFreq} options={prFreqOptions} values={prFreqValues}
                                                                    onChange={(e) => formChange("prFreq", parseInt(e.target.value))} required borderBottom />
                                                            )
                                                        }
                                                    })()}<br />
                                                    {(() => {
                                                        if (prFreq == 1) {
                                                            // Monthly
                                                            return (
                                                                <ModalDropDown options={daysOptions} values={daysValues} value={prDays}
                                                                    onChange={(e) => setPrDays(parseInt(e.target.value))} required borderBottom />
                                                            )
                                                        } else if (prFreq == 2 || prFreq == 3) {
                                                            // Year/Quarterly
                                                            return (
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <ModalDropDown options={monthOptions} values={monthValues} value={prMonth}
                                                                            onChange={(e) => setPrMonth(parseInt(e.target.value))} required borderBottom />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <ModalDropDown options={daysOptions} values={daysValues} value={prDays}
                                                                            onChange={(e) => setPrDays(parseInt(e.target.value))} required borderBottom />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })()}<br />
                                                    {(() => {
                                                        if (prFreq != -1) {
                                                            return (
                                                                <ModalMultiDropDown options={empOptions} values={empValues} value={prEmps}
                                                                    onChange={(e) => setPrEmps(
                                                                        Array.from(e.target.selectedOptions, options => parseInt(options.value)))} />
                                                            )
                                                        }
                                                    })()}<br />
                                                </React.Fragment>
                                            )
                                        }
                                    })()}
                                </div>
                                <div className="col-3">
                                    {(() => {
                                        if (stFreqOptions.length > 0 && !isUser) {
                                            return (
                                                <React.Fragment >
                                                    <ModalCheckbox label={"Sales Tax"} checked={st}
                                                        checkboxCallback={(e) => formChange("st", e.target.checked)} /><br />
                                                    {(() => {
                                                        if (st) {
                                                            return (
                                                                <ModalDropDown value={stFreq} options={stFreqOptions} values={stFreqValues}
                                                                    onChange={(e) => formChange("stFreq", parseInt(e.target.value))} required borderBottom />
                                                            )
                                                        }
                                                    })()}<br />
                                                    {(() => {
                                                        if (stFreq == 1) {
                                                            // Monthly
                                                            return (
                                                                <ModalDropDown options={daysOptions} values={daysValues} value={stDays}
                                                                    onChange={(e) => setStDays(parseInt(e.target.value))} required borderBottom />
                                                            )
                                                        } else if (stFreq == 2 || stFreq == 3) {
                                                            // Year/Quarterly
                                                            return (<div className="row">
                                                                <div className="col-6">
                                                                    <ModalDropDown options={monthOptions} values={monthValues} value={stMonth}
                                                                        onChange={(e) => setStMonth(parseInt(e.target.value))} required borderBottom />
                                                                </div>
                                                                <div className="col-6">
                                                                    <ModalDropDown options={daysOptions} values={daysValues} value={stDays}
                                                                        onChange={(e) => setStDays(parseInt(e.target.value))} required borderBottom />
                                                                </div>
                                                            </div>
                                                            )
                                                        }
                                                    })()}<br />
                                                    {(() => {
                                                        if (stFreq != -1) {
                                                            return (
                                                                <ModalMultiDropDown options={empOptions} values={empValues} value={stEmps}
                                                                    onChange={(e) => setStEmps(
                                                                        Array.from(e.target.selectedOptions, options => parseInt(options.value)))} />
                                                            )
                                                        }
                                                    })()}<br />
                                                </React.Fragment>
                                            )
                                        }
                                    })()}
                                </div>
                                <div className="col-3">
                                    {(() => {
                                        if (tpFreqOptions.length > 0 && !isUser) {
                                            return (
                                                <React.Fragment>
                                                    <ModalCheckbox label={"Tax Planning"} checked={tp}
                                                        checkboxCallback={(e) => formChange("tp", e.target.checked)} /><br />
                                                    {(() => {
                                                        if (tp) {
                                                            return (
                                                                <ModalDropDown value={tpFreq} options={tpFreqOptions} values={tpFreqValues}
                                                                    onChange={(e) => formChange("tpFreq", parseInt(e.target.value))} required borderBottom />
                                                            )
                                                        }
                                                    })()}<br />
                                                    {(() => {
                                                        if (tpFreq == 1) {
                                                            // Monthly
                                                            return (
                                                                <ModalDropDown options={daysOptions} values={daysValues} value={tpDays}
                                                                    onChange={(e) => setTpDays(parseInt(e.target.value))} required borderBottom />
                                                            )
                                                        } else if (tpFreq == 2 || tpFreq == 3) {
                                                            // Year/Quarterly
                                                            return (
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <ModalDropDown options={monthOptions} values={monthValues} value={tpMonth}
                                                                            onChange={(e) => setTpMonth(parseInt(e.target.value))} required borderBottom />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <ModalDropDown options={daysOptions} values={daysValues} value={tpDays}
                                                                            onChange={(e) => setTpDays(parseInt(e.target.value))} required borderBottom />
                                                                    </div>
                                                                </div>)
                                                        }
                                                    })()}<br />
                                                    {(() => {
                                                        if (tpFreq != -1) {
                                                            return (
                                                                <ModalMultiDropDown options={empOptions} values={empValues} value={tpEmps}
                                                                    onChange={(e) => setTpEmps(
                                                                        Array.from(e.target.selectedOptions, options => parseInt(options.value)))} />
                                                            )
                                                        }
                                                    })()}<br />
                                                </React.Fragment>
                                            )
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                        <ModalFooter saveCallback={formSubmit} saveInactive={false} disable={disable} />
                    </div>
                </div>
            </div>
        )
    }
    return ReactDOM.createPortal(relativeRender(), document.getElementById("modal") as Element);
}

export default ModalBusiness;


